export const ComingSoon = () => {
    return (
        <div className="coming-soon" style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', padding: 10 }}>
            <div>
                <img style={{maxWidth: '100%', height: "auto"}} src={`img/coming-soon/logo-coming-soon.png`}
                     alt="safety invest consultiong logo"/>
            </div>
            <h1 style={{fontSize: 60}} className="coming-soon__title">Coming Soon</h1>
            <p style={{fontSize: 20}} className="coming-soon__text">We are working hard to give you the best
                experience.</p>
        </div>
    );
}